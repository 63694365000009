*,
*::after,
*::before {
  padding: 0;
  margin: 0rem;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
}

:root {
  --dark: #000;
  --dark-t: rgba(0, 0, 0, 0.6);
  --dark-s: #1d1d1f;
  --light: #fff;
  --light-shade: #d9d9d9;

  --pink: #e9bfde;
  --green: #d2e1b4;
  --blue: #d8f1ff;
  --red: #ff5252;
  --red-shadow: 5px 5px 13px #561818, -5px -5px 13px #ff6262;
  --blue-dark: rgb(26, 115, 232);
  --blue-dark-shadow: 5px 5px 13px #184456, -5px -5px 13px #62ffff;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  border-radius: 4px;
  color: var(--dark-s);
}
*::-webkit-scrollbar-thumb {
  border: 2px solid var(--dark-s);
  border-radius: 4px;
  background: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: min(1440px, 100%);
  margin: 0 auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 560px) {
  html {
    font-size: 14px;
  }
}
